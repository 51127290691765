import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardText, FormGroup, Label, Input } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import "./Corporate.css";

const CorporateInvoiceModal = ({ order }) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [patientDetail, setPatientDetail] = useState(null);
  const [labDetail, setLabDetail] = useState(null);
  const [testDetail, setTestDetail] = useState(null);
  //   const getPaymentDetail = async () => {
  //     await AxiosInstance.get(`${getLabOrderDetailByIdApi}/${orderId}`)
  //       .then((response) => {
  //         if (response != null) {
  //           setOrderDetail(response);
  //           setPatientDetail(response.patientDetail);
  //           setLabDetail(response.labDetail);
  //           setTestDetail(response.testDetail);
  //         } else {
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("err: ", err);
  //       });
  //   };

  //   useEffect(() => {
  //     getPaymentDetail();
  //   }, []);
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  console.log("specific order", order);
  return (
    <>
      <div className="PurchaseOrderHeading">
        Invoice For Uncurl:health Subscription
      </div>
      <Row>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Phone</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value={7506753773}
              type="number"
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Email</b>
            </Label>
            <Input disabled value="anjan@livingscopehealth.com" type="email" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>State</b>
            </Label>
            <Input disabled value="Maharastra" type="text" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>GSTIN</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="27AAFCL3118G1ZP"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Address</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="HD 090, Chromium, Milind Nagar, Powai-400076"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr style={{ border: "1px solid #000000" }}></hr>
      <Card className="LabOrderDetailCard">
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="todayDate">
                <b>Date:</b>
              </Label>
              <div>{order.date}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientType">
                <b>Type of Client:</b>
              </Label>
              <div>{order.typeOfClient}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientName">
                <b>Client Name:</b>
              </Label>
              <div>{order.clientName}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientEmail">
                <b>Client Email:</b>
              </Label>
              <div>{order.clientEmail}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientContactNo">
                <b>Client Contact No:</b>
              </Label>
              <div>{order.clientPhoneNumber}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientAddress">
                <b>Client Address (Test collection site):</b>
              </Label>
              <div>{order.clientAddress}</div>
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <TableContainer
        component={Paper}
        sx={{ border: "1px solid black", borderRadius: "5px" }}
        elevation={5}
        className="mt-3 mb-3"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Plan Name
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                No. of Employees
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Total Price
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                GST (18%)
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ borderRight: "1px solid black" }}>
                {order.planName}
              </TableCell>
              <>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.category}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.noOfEmployees}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.totalPrice}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {(order.totalPrice * 0.18).toFixed(2)}
                </TableCell>
                <TableCell
                // rowSpan={testNames.length}
                >
                  {(order.totalPrice * (1 + 0.18)).toFixed(2)}
                </TableCell>
              </>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Row>
        <b style={{ textDecoration: "underline" }}>Notes to Lab:</b>
        <ul className="Labnotes">
          <li>
            Please call our client promptly at the contact number provided above
            to coordinate the tests collection as per date and time mentioned
            above
          </li>
          <li>
            After tests are done and reports are available, please upload them
            on Uncurl: health app platform.
          </li>
          <li>
            If there are any questions regarding this order or the PO amount
            shown above does not match as per your quote, then please contact
            Living Scope Health at the number provided and resolve.
            <b>
              You are not supposed to discuss anything with our client regarding
              this purchase order or the amount stated in it
            </b>
          </li>
          <li>
            Invoice also to be attached on Uncurl: health app platform. Payment
            will be done though our payment portal within seven days after
            submission of invoice and all test reports on our Uncurl: health
            platform.
          </li>
        </ul>

        <div>
          <b>
            Note: This is an electronically generated order and so does not
            require any signature for approval
          </b>
        </div>
      </Row> */}
    </>
  );
};

export default CorporateInvoiceModal;
