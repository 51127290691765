import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
} from "@mui/material";

// Sample data
const subscribedData = [
  {
    date: "Jan'25",
    employeesSubscribed: 100,
    amountPaid: 5000,
    paymentDate: "01-25",
  },
  {
    date: "Feb'25",
    employeesSubscribed: 120,
    amountPaid: 6000,
    paymentDate: "02-25",
  },
  {
    date: "Mar'25",
    employeesSubscribed: 110,
    amountPaid: 5500,
    paymentDate: "03-25",
  },
];

const leftData = [
  {
    month: "Jan'25",
    employeesLeft: 25,
    unusedAmount: 3500,
    cumAmount: 3500,
    comment: "See full list",
  },
  {
    month: "Feb'25",
    employeesLeft: 10,
    unusedAmount: 1800,
    cumAmount: 5300,
    comment: "See full list",
  },
];

const CorporateWallet = () => {
  const walletCredit = 5300;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "10px",
        }}
      >
        <span
          style={{
            backgroundColor: "#e0f7fa",
            color: "#00796b",
            padding: "8px 8px",
            borderRadius: 1,
            fontWeight: "bold",
            boxShadow: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.1rem",
            border: "5px solid teal",
          }}
        >
          Credit in Wallet: ${walletCredit}
        </span>
      </Box>
      <Grid container spacing={2}>
        {/* Employees Subscribed Table */}
        <Grid item md={6}>
          <TableContainer
            component={Paper}
            sx={{ bgcolor: "#ffebcc", height: "100%" }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ bgcolor: "#ff8c00", color: "#fff", padding: 1 }}
            >
              EMPLOYEES SUBSCRIBED
            </Typography>
            <Table size="small" sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "10px" }}>Date/Month</TableCell>
                  <TableCell sx={{ padding: "10px" }}>
                    No. of Employees Subscribed
                  </TableCell>
                  <TableCell sx={{ padding: "10px" }}>Amount Paid</TableCell>
                  <TableCell sx={{ padding: "10px" }}>Payment Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscribedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: "10px" }}>{row.date}</TableCell>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.employeesSubscribed}
                    </TableCell>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.amountPaid}
                    </TableCell>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.paymentDate}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Employees Left Table */}
        <Grid item md={6}>
          <TableContainer
            component={Paper}
            sx={{ bgcolor: "#e6f2ff", height: "100%" }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ bgcolor: "#0073e6", color: "#fff", padding: 1 }}
            >
              EMPLOYEES LEFT THE ORGANIZATION
            </Typography>
            <Table size="small" sx={{ whiteSpace: "nowrap" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: "10px" }}>Month</TableCell>
                  <TableCell sx={{ padding: "10px" }}>
                    No. of Employees Left
                  </TableCell>
                  <TableCell sx={{ padding: "10px" }}>Unused Amount</TableCell>
                  <TableCell sx={{ padding: "10px" }}>Cum Amount</TableCell>
                  <TableCell sx={{ padding: "10px" }}>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leftData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: "10px" }}>{row.month}</TableCell>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.employeesLeft}
                    </TableCell>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.unusedAmount}
                    </TableCell>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.cumAmount}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px",
                        color: "#0073e6",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {row.comment}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default CorporateWallet;
