import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Typography,
} from "@mui/material";

// Sample data structure for plans
const plans = [
  {
    planType:
      "GOLD-Prevent & Protect From Lifestyle Diseases- BASIC PLAN (GBP)",
    planDetails: [
      {
        name: "GBP1",
        category: "Individual /Self",
        cost: 159,
        price: 1908,
        comingSoon: false,
        employees: null,
      },
      {
        name: "GBP2",
        category: "Self + Spouse",
        // cost: 199,
        // price: 2388,
        comingSoon: true,
        employees: null,
      },
      {
        name: "GBP2",
        category: "Self+ Spouse + Children",
        // cost: 199,
        // price: 2388,
        comingSoon: true,
        employees: null,
      },
      {
        name: "GBP2",
        category: "Self+ Spouse + Children + Parents",
        // cost: 199,
        // price: 2388,
        comingSoon: true,
        employees: null,
      },
    ],
  },
  {
    planType:
      "GOLD-Prevent & Protect From Lifestyle Diseases- ADVANCE PLAN (GAP)",
    planDetails: [
      {
        name: "GAP1",
        category: "Individual /Self",
        cost: 179.83,
        price: 2158,
        comingSoon: false,
        employees: null,
      },
    ],
  },
  {
    planType:
      "GOLD-Prevent & Protect  From Lifestyle Diseases- PREMIUM PLAN (GPP)",
    planDetails: [
      {
        name: "GPP1",
        category: "Individual /Self",
        cost: 246.5,
        price: 2958,
        comingSoon: false,
        employees: null,
      },
    ],
  },
  {
    planType:
      "GOLD-Prevent & Protect  From Lifestyle Diseases- Lifestyle Coaching (GLC)",
    planDetails: [
      {
        name: "GLC1",
        category: "Individual /Self",
        cost: 263.17,
        price: 3158,
        comingSoon: false,
        employees: null,
      },
    ],
  },
];

const CorporateSubscriptionPlans = () => {
  const [plansState, setPlansState] = useState(plans);
  const [selectedRow, setSelectedRow] = useState(null);
  const [validationMessages, setValidationMessages] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const handleEmployeeChange = (planIndex, detailIndex, value) => {
    const updatedPlans = [...plansState];
    updatedPlans[planIndex].planDetails[detailIndex].employees = value;
    setPlansState(updatedPlans);

    // Reset validation message for this specific field
    setValidationMessages((prev) => ({
      ...prev,
      [`${planIndex}-${detailIndex}`]: "",
    }));
  };

  const handleRowClick = (planIndex, detailIndex) => {
    console.log("plan", planIndex, detailIndex);
    const selectedDetail = plansState[planIndex].planDetails[detailIndex];
    setSelectedRow(
      selectedRow === `${planIndex}-${detailIndex}`
        ? null
        : `${planIndex}-${detailIndex}`
    ); // Toggle selection
    setShowSubmitButton(
      selectedRow !== `${planIndex}-${detailIndex}` &&
        !selectedDetail.comingSoon
    ); // Show submit button after selecting a row
  };

  const handleSubmit = () => {
    const selectedIndex = selectedRow?.split("-");
    if (selectedIndex) {
      const [planIndex, detailIndex] = selectedIndex;
      const selectedDetail = plansState[planIndex].planDetails[detailIndex];

      // Check if the selected row's "Number of Employees" is valid
      if (!selectedDetail.employees || selectedDetail.employees <= 0) {
        setValidationMessages((prev) => ({
          ...prev,
          [`${planIndex}-${detailIndex}`]: "This field cannot be empty.",
        }));
        return;
      }

      // Calculate the total price for this row
      const totalPrice = selectedDetail.employees * selectedDetail.price;

      // If all is valid, log the selected row's details along with total price
      console.log({
        planName: selectedDetail.name,
        category: selectedDetail.category,
        monthlyPrice: selectedDetail.cost,
        yearlyPrice: selectedDetail.price,
        additionalCost: selectedDetail.additionalCost || 0,
        numberOfEmployees: selectedDetail.employees,
        totalPrice: totalPrice, // Adding total price to the object
      });
    }
  };

  const getPlanBackgroundColor = (planType) => {
    if (planType.includes("BASIC PLAN")) return "#8bc34a61"; // Basic Plan
    if (planType.includes("ADVANCE PLAN")) return "#03a9f445"; // Advance Plan
    if (planType.includes("PREMIUM PLAN")) return "#ff572252"; // Premium Plan
    if (planType.includes("Lifestyle Coaching")) return "#ff149342"; // Lifestyle Coaching
    return "inherit"; // Default color
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "dimgray" }}>
              <TableCell sx={{ padding: "8px", color: "white" }}>
                Plan Name
              </TableCell>
              <TableCell sx={{ padding: "8px", color: "white" }}>
                Category
              </TableCell>
              <TableCell sx={{ padding: "8px", color: "white" }}>
                Monthly Price
              </TableCell>
              <TableCell sx={{ padding: "8px", color: "white" }}>
                Yearly Price
              </TableCell>
              <TableCell sx={{ padding: "8px", color: "white" }}></TableCell>
              <TableCell sx={{ padding: "8px", color: "white" }}>
                Number of Employees
              </TableCell>
              <TableCell sx={{ padding: "8px", color: "white" }}>
                Total Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plansState.map((plan, planIndex) => (
              <React.Fragment key={plan.planType}>
                {/* Plan Type Header Row */}
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{
                      backgroundColor: getPlanBackgroundColor(plan.planType),
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {plan.planType}
                  </TableCell>
                </TableRow>
                {/* Plan Details Rows */}
                {plan.planDetails.map((detail, detailIndex) => {
                  const isSelected =
                    selectedRow === `${planIndex}-${detailIndex}`;
                  return (
                    <TableRow
                      key={detailIndex}
                      onClick={() => handleRowClick(planIndex, detailIndex)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: isSelected ? "lightblue" : "inherit",
                        "&:hover": {
                          backgroundColor: "lightgray",
                        },
                      }}
                    >
                      <TableCell>{detail.name}</TableCell>
                      <TableCell>{detail.category}</TableCell>
                      <TableCell>{detail.cost}</TableCell>
                      <TableCell>{detail.price}</TableCell>
                      <TableCell>
                        {detail.comingSoon ? "COMING SOON" : ""}
                      </TableCell>
                      <TableCell>
                        {detail.comingSoon ? (
                          ""
                        ) : (
                          <TextField
                            size="small"
                            variant="outlined"
                            value={detail.employees || ""}
                            onChange={(e) =>
                              handleEmployeeChange(
                                planIndex,
                                detailIndex,
                                e.target.value
                              )
                            }
                            inputProps={{
                              style: {
                                padding: "4px 8px",
                                fontSize: "0.875rem",
                              },
                            }}
                          />
                        )}
                        {/* Display error message only for selected row */}
                        {validationMessages[`${planIndex}-${detailIndex}`] && (
                          <Typography color="error" sx={{ mt: 1 }}>
                            {validationMessages[`${planIndex}-${detailIndex}`]}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {detail.employees
                          ? detail.employees * detail.price
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Submit Button */}
      {showSubmitButton && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default CorporateSubscriptionPlans;
