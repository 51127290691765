import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Row } from "reactstrap";
import { Card, FormLabel } from "@mui/material";
import "../profileRegistration/formStyle.css";
import "./LifeStyle.css";
import Spinner from "../../shared/Spinner";

const MyFitness = () => {
  const [myFitness, setmyFitness] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("form/lifestyleSketchDiet")
      .then((response) => {
        if (response !== null) {
          setmyFitness(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  console.log("fitness", myFitness);
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Moderate Intensity Exercise</h5>
            </div>
            <div
              style={{
                padding: "0px 10px 10px 10px",
                fontWeight: "500",
                color: "crimson",
              }}
            >
              Note: This includes physical activities that get you breathing
              harder and your heart beating faster. Examples of exercise include
              setting aside time for things like: jogging, dancing, bike riding,
              aerobic classes, swimming, working out to an exercise video.
              Exercise does not include what you do at work.
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. How many days in a week do you do moderate intensity
                    exercise for at least 10 minutes at a time without stopping
                    :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.moderateIntensityExerciseDays}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    2. On those days, how much time (in minutes) do you spend on
                    average doing the activities:
                  </label>
                  <span className="inputDiet">
                    {myFitness?.averageTimeForActivities}
                  </span>

                  <label style={{ marginLeft: "10px", fontWeight: "500" }}>
                    Total Minutes (in a week) :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.moderateIntensityExerciseDays *
                      myFitness?.averageTimeForActivities}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg={11}>
                  <label className="questionLabel">
                    3. Walking fast (3-4 mph) is also exercise. How many days in
                    a week do you walk fast for at least 10 minutes at a time
                    without stopping :
                  </label>
                </Col>
                <Col lg={1}>
                  <span className="inputDiet">
                    {" "}
                    {myFitness?.fastWalkingDays}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    4. On those days, how much time (in minutes) did you spend
                    on average walking fast :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.averageTimeForFastWalking}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Vigorous Intensity Exercise</h5>
            </div>
            <div
              style={{
                padding: "0px 0px 10px 10px",
                fontWeight: "500",
                color: "crimson",
              }}
            >
              Note: This includes physical activities like Race walking,
              jogging, running , Bicycling 10 mph or faster , Swimming laps ,
              Aerobic dancing , Jumping rope , Heavy gardening (continuous
              digging or hoeing with heart rate increases) , Hiking uphill or
              with a heavy backpack.
            </div>
            <div className="dietContent" style={{ paddingRight: "0px" }}>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. How many days in a week do you do Vigorous intensity
                    exercise for at least 10 minutes at a time without stopping
                    :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.daysForVigorousIntensityExerciseForTenMinutes}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    2. On those days, how much time (in minutes) do you spend on
                    average doing the Vigorous activities :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.timeSpentInVigorousIntensityExercise}
                  </span>
                  <label style={{ marginLeft: "10px", fontWeight: "500" }}>
                    Total Minutes (in a week) :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.daysForVigorousIntensityExerciseForTenMinutes *
                      myFitness?.timeSpentInVigorousIntensityExercise}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Regular mild or Mild Intensity</h5>
            </div>
            <div
              style={{
                padding: "0px 10px 10px 10px",
                fontWeight: "500",
                color: "crimson",
              }}
            >
              Note: This is any physical exercise that gets your heart rate less
              than 50% higher than your resting heart rate. Examples includes-
              walking slowly, using computer, standing light work (cooking,
              washing dishes), fishing, playing most instruments, etc. Use this
              definition to answer the questions below.
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. How many days in a week do you do Mild intensity exercise
                    for at least 10 minutes :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.daysForMildIntensityExerciseForTenMinutes}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    2. On those days, how much time (in minutes) do you spend on
                    average doing the Mild activities :
                  </label>
                  <span className="inputDiet">
                    {myFitness?.timeSpentInMildIntensityExercise}
                  </span>
                  <label style={{ marginLeft: "10px", fontWeight: "500" }}>
                    Total Minutes (in a week) :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.daysForMildIntensityExerciseForTenMinutes *
                      myFitness?.timeSpentInMildIntensityExercise}
                  </span>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Last Seven Days Trend</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. Compared to how physically active you have been over the
                    last 3 months, how would you describe the last 7 days :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.comparisonLastThreeMonthsToSevenDays}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    2. What forms of activity do you undertake regularly :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.activityUndertakeRegularly}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    3. How many hours did you exercise in the last 7 days :{" "}
                  </label>
                  <span className="inputDiet">
                    {myFitness?.hoursExerciseInLastSevenDays}
                  </span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    4. On how many of the last SEVEN DAYS did you participate in
                    at least 30 minutes of physical activity? (Total minutes of
                    continuous activity, including walking) :{" "}
                    <span className="inputDiet">
                      {myFitness?.exerciseAtLeastThirtyMinutesLastSevenDays}
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-3 dietHeadings">
              <h5>Sedentary Exercise</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-2">
                <Col>
                  <label className="questionLabel">
                    1. How many total hours do you sit (sitting time) in a day?
                    (include time sitting while working, watching movies or
                    video, talking on mobile, sitting while commuting for work,
                    etc.) :
                    <span className="inputDiet">
                      {myFitness?.timeSpentInSittingPerDay}
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default MyFitness;
