import React, { useEffect, useState } from "react";
import Stairs from "./Stairs";
import { Grid } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LineChart,
  Line,
  LabelList,
} from "recharts";
import "./Corporate.css";
import CorporateRiskTable from "./CorporateRiskTable";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import CorporateSymptomsMonthly from "./CorporateSymptomsMonthly";

const CorporateDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [allEmployeeVisit, setallEmployeeVisit] = useState();
  const [allEmployeeRisk, setallEmployeeRisk] = useState();
  const [allEmployeeOverAllRisk, setallEmployeeOverAllRisk] = useState();
  // const Linedata = [
  //   { month: "JAN2024", TotalHospitalVisits: 20, CumulativeCountVisits: 28 },
  //   { month: "FEB2024", TotalHospitalVisits: 24, CumulativeCountVisits: 44 },
  //   { month: "MAR2024", TotalHospitalVisits: 32, CumulativeCountVisits: 76 },
  //   { month: "APR2024", TotalHospitalVisits: 12, CumulativeCountVisits: 88 },
  //   { month: "MAY2024", TotalHospitalVisits: 21, CumulativeCountVisits: 109 },
  //   { month: "JUNE2024", TotalHospitalVisits: 24, CumulativeCountVisits: 133 },
  //   { month: "JULY2024", TotalHospitalVisits: 55, CumulativeCountVisits: 188 },
  //   { month: "AUG2024", TotalHospitalVisits: 43, CumulativeCountVisits: 231 },
  //   { month: "SEP2024", TotalHospitalVisits: 68, CumulativeCountVisits: 299 },
  //   { month: "OCT2024", TotalHospitalVisits: 32, CumulativeCountVisits: 331 },
  // ];

  useEffect(() => {
    const referCode = "GUBAMADAS1";
    AxiosInstance.get(`corporate/get-all-employee-visit/${referCode}`)
      .then((response) => {
        if (response != null) {
          setallEmployeeVisit(response);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err: ", err);
      });
    AxiosInstance.get(`corporate/get-all-employee-risk/${referCode}`)
      .then((response) => {
        if (response != null) {
          setallEmployeeRisk(response);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err: ", err);
      });
    AxiosInstance.get(`corporate/get-all-employee-overallRisk/${referCode}`)
      .then((response) => {
        if (response != null) {
          setallEmployeeOverAllRisk(response);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err: ", err);
      });
  }, []);

  const Mediumdata = Object.entries(allEmployeeRisk?.riskScoreMedium || {}).map(
    ([name, value]) => ({ name, value })
  );
  const Moderatedata = Object.entries(
    allEmployeeRisk?.riskScoreModerate || {}
  ).map(([name, value]) => ({ name, value }));

  const Highdata = Object.entries(allEmployeeRisk?.riskScoreHigh || {}).map(
    ([name, employees]) => ({ name, employees })
  );

  const generateColors = (numColors) => {
    return Array.from(
      { length: numColors },
      () =>
        `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .padStart(6, "0")}`
    );
  };

  // Generate colors based on the length of Mediumdata
  const COLORS = generateColors(Mediumdata.length);

  const currentYear = new Date().getFullYear();

  // Mapping of month names to short format with the dynamic current year
  const monthMap = {
    January: `JAN${currentYear}`,
    February: `FEB${currentYear}`,
    March: `MAR${currentYear}`,
    April: `APR${currentYear}`,
    May: `MAY${currentYear}`,
    June: `JUNE${currentYear}`,
    July: `JULY${currentYear}`,
    August: `AUG${currentYear}`,
    September: `SEP${currentYear}`,
    October: `OCT${currentYear}`,
    November: `NOV${currentYear}`,
    December: `DEC${currentYear}`,
  };

  // Function to convert monthlyVisit to Linedata format
  const convertToLinedata = (monthlyVisit) => {
    let cumulativeCount = 0;
    return Object.entries(monthMap).map(([monthName, shortName]) => {
      const visits = monthlyVisit[monthName] || 0;
      cumulativeCount += visits; // Calculate cumulative count

      return {
        month: shortName,
        TotalHospitalVisits: visits,
        CumulativeCountVisits: cumulativeCount,
      };
    });
  };

  // Use the function to get Linedata
  const Linedata = convertToLinedata(allEmployeeVisit?.monthlyVisit || {});
  return (
    <div>
      <h4 style={{ textAlign: "center", marginBottom: "15px" }}>
        Corporate Health Risk Dashboard
      </h4>
      <Grid container spacing={2} mb={4}>
        <Grid item lg={6}>
          <div
            className="chart-item"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <h6>Lifestyle Induced Health Risk Score</h6>
            <h6>Number of Employees at Varying Levels of Risk</h6>
            {allEmployeeRisk?.employeeCount === null &&
            allEmployeeRisk?.riskScore === null ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <p>No Data Available</p>
              </div>
            ) : (
              <Stairs allEmployeeRisk={allEmployeeRisk} />
            )}
          </div>
        </Grid>
        <Grid item lg={3}>
          <div
            className="chart-item"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <h6 style={{ textAlign: "center" }}>
              No. of Emplyoees with Medium Health Risks
            </h6>
            {allEmployeeRisk?.riskScoreMedium === null ||
            Object.keys(allEmployeeRisk?.riskScoreMedium || {}).length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <p>No Data Available</p>
              </div>
            ) : (
              <PieChart width={250} height={280}>
                <Pie
                  data={Mediumdata}
                  cx="40%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, value }) => `${value}`}
                  outerRadius={50}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {Mediumdata.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            )}
          </div>
        </Grid>
        <Grid item lg={3}>
          <div
            className="chart-item"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <h6 style={{ textAlign: "center" }}>
              No. of Emplyoees with Moderate Health Risks
            </h6>
            {allEmployeeRisk?.riskScoreModerate === null ||
            Object.keys(allEmployeeRisk?.riskScoreModerate || {}).length ===
              0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <p>No Data Available</p>
              </div>
            ) : (
              <PieChart width={250} height={280}>
                <Pie
                  data={Moderatedata}
                  cx="40%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, value }) => `${value}`}
                  outerRadius={50}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {Moderatedata.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={4}>
        <Grid item lg={6}>
          <div
            className="chart-item"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <h6 style={{ textAlign: "center" }}>
              No. of Emplyoees with High Health Risks
            </h6>
            {allEmployeeRisk?.riskScoreHigh === null ||
            Object.keys(allEmployeeRisk?.riskScoreHigh || {}).length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <p>No Data Available</p>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  data={Highdata}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 60, // Increased bottom margin to fit label and legend
                  }}
                >
                  <CartesianGrid
                    strokeDasharray="3 3"
                    horizontal={true}
                    vertical={false}
                  />
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    label={{
                      value: "No. of Employees : 4",
                      position: "bottom",
                      dy: 20,
                    }} // Custom label
                  />
                  <YAxis
                    allowDecimals={false}
                    axisLine={false}
                    tickLine={false}
                  />
                  <Tooltip />
                  <Bar dataKey="employees">
                    {Highdata.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
                {/* Legend positioned below the chart */}
              </ResponsiveContainer>
            )}
          </div>
        </Grid>
        <Grid item lg={6}>
          <div
            className="chart-item"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <h6 style={{ textAlign: "center" }}>
              Total Hospital Visits by Emplyoees
            </h6>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={Linedata}
                margin={{ top: 20, right: 30, left: 20, bottom: 0 }} // Increased bottom margin for month labels
              >
                {/* Keep only horizontal lines */}
                <CartesianGrid vertical={false} strokeDasharray="3 3" />

                {/* X-Axis shows all months with slanted labels and increased space for clarity */}
                <XAxis
                  dataKey="month"
                  interval={0} // Forces all labels to show
                  angle={-45} // Slant labels to 45 degrees
                  textAnchor="end" // Align the slanted labels
                  height={80} // Increase space for month labels
                />

                {/* Y-Axis without the axis line */}
                <YAxis axisLine={false} />

                <Tooltip />

                {/* First line with numbers inside */}
                <Line
                  type="monotone"
                  dataKey="TotalHospitalVisits"
                  stroke="#0088FE"
                  strokeWidth={3}
                  activeDot={{ r: 8 }}
                  dot={{ r: 15 }}
                >
                  <LabelList dataKey="TotalHospitalVisits" position="center" />
                </Line>

                {/* Second line with numbers inside */}
                <Line
                  type="monotone"
                  dataKey="CumulativeCountVisits"
                  stroke="#FF8042"
                  strokeWidth={3}
                  activeDot={{ r: 8 }}
                  dot={{ r: 15 }}
                >
                  <LabelList
                    dataKey="CumulativeCountVisits"
                    position="center"
                  />
                </Line>

                {/* Legend placed below all months with additional margin */}
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  margin={{ top: 80 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <CorporateRiskTable></CorporateRiskTable>
        </Grid>
        <Grid item lg={6}>
          <CorporateSymptomsMonthly
            symptomsMonth={allEmployeeVisit?.symptomsMonth}
          ></CorporateSymptomsMonthly>
        </Grid>
      </Grid>
    </div>
  );
};

export default CorporateDashboard;
