// const CorporateEmployeesList = () => {
//   return <div>Corporate Employees List</div>;
// };

// export default CorporateEmployeesList;

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";

const CorporateEmployeesList = () => {
  // Sample data for demonstration
  const sampleData = [
    {
      dateAdded: "2024-01-01",
      employeeId: "E001",
      locationCode: "NYC",
      firstName: "John",
      lastName: "Doe",
      middleInitial: "A",
      mobile: "123-456-7890",
      email: "john.doe@example.com",
      planName: "Basic Plan",
      dateOfSubscription: "2024-01-05",
      status: "Active",
    },
    {
      dateAdded: "2024-01-10",
      employeeId: "E002",
      locationCode: "LA",
      firstName: "Jane",
      lastName: "Smith",
      middleInitial: "",
      mobile: "234-567-8901",
      email: "jane.smith@example.com",
      planName: "Premium Plan",
      dateOfSubscription: "2024-01-15",
      status: "Active",
    },
  ];

  const StyledTableCell = styled(TableCell)({
    fontWeight: "bold",
    backgroundColor: "#1976d2", // Direct color value
    color: "#fff",
    textTransform: "uppercase",
  });

  const StyledTableRow = styled(TableRow)(({ index }) => ({
    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#fff",
  }));

  const statusColor = (status) => (status === "Active" ? "green" : "gray");

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        This page provides a current list of all employees of your organization
        for whom you have requested subscription for Uncurl:health app.
      </Typography>
      <Typography variant="body2" gutterBottom>
        1. You may at any time add new employees by simply uploading a revised
        list with new employee's name added to it.
      </Typography>
      <Typography variant="body2" gutterBottom>
        2. A month end invoice will be generated for payment for all new
        employees added to the initial list.
      </Typography>
      <Typography variant="body2" gutterBottom>
        3. New employees will be added for subscription on the fifth of each
        month only after subscription payment has been received and credited to
        each employee's account in Uncurl: health.
      </Typography>
      <Typography variant="body2" gutterBottom>
        4. Please note that once added to the plan, employees cannot be removed
        from the plan unless they leave the organization.
      </Typography>
      <Typography variant="body2" gutterBottom>
        5. Once an employee leaves the organization, their unused subscription
        plan amount will be added to your WALLET that you may use for payment
        purposes later.
      </Typography>

      <TableContainer component={Paper} style={{ marginTop: "16px" }}>
        <Table style={{ whiteSpace: "nowrap" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Date Added</StyledTableCell>
              <StyledTableCell>Employee ID</StyledTableCell>
              <StyledTableCell>Location Code/City</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Middle Initial (if any)</StyledTableCell>
              <StyledTableCell>Mobile</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Plan Name</StyledTableCell>
              <StyledTableCell>Date of Subscription</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sampleData.map((row, index) => (
              <StyledTableRow key={index} index={index}>
                <TableCell>{row.dateAdded}</TableCell>
                <TableCell>{row.employeeId}</TableCell>
                <TableCell>{row.locationCode}</TableCell>
                <TableCell>{row.firstName}</TableCell>
                <TableCell>{row.lastName}</TableCell>
                <TableCell>{row.middleInitial}</TableCell>
                <TableCell>{row.mobile}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.planName}</TableCell>
                <TableCell>{row.dateOfSubscription}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    style={{
                      backgroundColor: statusColor(row.status),
                      color: "white",
                    }}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CorporateEmployeesList;
