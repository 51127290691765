import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../../configration/AxiosConfiguration";
import { getRiskDiseaseMappingApi } from "../../../constants/apiList";
import { getColorByLabel } from "../../../util/Helper";
import { columns } from "./RisksDiseaseMapColumns";
import { Col, Row } from "reactstrap";

const RisksDiseaseMap = ({RiskDiseaseMapNext}) => {
  const [riskModifiableData, setModifiableData] = useState();
  const [riskNonModifiableData, setNonModifiableData] = useState();
  const [keys, setKeys] = useState([]);

  const getRiskDiseaseMapping = async () => {
    AxiosInstance.get(`${getRiskDiseaseMappingApi}`)
      .then((res) => {
        setModifiableData(res?.modifiableRiskDiseaseMapping);
        setNonModifiableData(res?.unModifiableRiskDiseaseMapping);
      })
      .catch(() => {
        console.log("Error");
      });
  };

 

  const filteredColumns = columns?.filter((column) => {
    if (column.id === "label") return true;
    return riskModifiableData?.some((item) => item[column.id] !== null);
  });

  const filteredData = riskModifiableData?.filter((item) => Object.values(item).some((value) => value !== null && typeof value !== "object"));

  const filteredNonColumns = columns?.filter((column) => {
    if (column.id === "label") return true; 
    return riskNonModifiableData?.some((item) => item[column.id] !== null);
  });

  const filteredNonData = riskNonModifiableData?.filter((item) => Object.values(item).some((value) => value !== null && typeof value !== "object"));

  useEffect(() => {
    getRiskDiseaseMapping();
  }, []);

  useEffect(() => {
    const riskLevels = ['Medium', 'Moderate', 'High'];
    const result = [];

    if (riskNonModifiableData && riskNonModifiableData.length > 0) {
        // Loop through each property of the first object
        for (const [key, value] of Object.entries(riskNonModifiableData[0])) {
            if (value && value.riskLevel && riskLevels.includes(value.riskLevel)) {
                result.push(key);
            }
        }
        setKeys(result);
    }
}, [riskNonModifiableData])

  console.log("filtered" , filteredNonData , filteredData , keys , riskNonModifiableData , filteredColumns , filteredNonColumns)
 
  return (
    <>
    <Row>
      <Col lg={12}>

        <TableContainer
          component={Paper}
          className="risk-disease-map-table mb-2"
        >
          <Table size="small">
            <TableHead sx={{ whiteSpace: "nowrap", background: "#EEEDEB",  textTransform: "capitalize" }}>
              <TableRow>
                {filteredColumns.map((column, columnIndex) => {
                  console.log("columnIndex", columnIndex);
                  return (
                   <>{column.id === "cld" || column.id === "overWeightObesity" || column.id === "highBloodPressure" ?
                    <TableCell
                      key={column.id}
                      sx={{ minWidth: "240px" ,  borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: `${columnIndex == 0 ? "left" : "center"}` }}
                    >
                      {column.label}
                    </TableCell> : <TableCell
                    key={column.id}
                    sx={{ minWidth: column.id === 'label' ? "185px" :"110px" ,  borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                  >
                    {column.label}
                  </TableCell>}</> 
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {/* {filteredColumns.map((column) => {
                    if (column.id === "label") {
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          {rowData.riskFactorDisease}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={column.id}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                      >
                        {rowData[column.id]?.value ? "Y" : ""}&nbsp;&nbsp;
                        {rowData[column.id]?.value && (
                          <span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel(rowData[column.id]?.riskLevel) }}
                          ></span>
                        )}
                      </TableCell>
                    );
                  })} */}

                  {filteredColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                    >
                      {column.id === 'label'
                        ? rowData.riskFactorDisease
                        : rowData[column.id]?.value
                          ? <>
                              Y&nbsp;&nbsp;
                              <span
                                className="risk-level-circle"
                                style={{ background: getColorByLabel(rowData[column.id]?.riskLevel) , border: "2px black solid" }}
                              ></span>
                            </>
                          : null
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
 
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableContainer
          component={Paper}
          className="risk-disease-map-table"
        >
          <Table size="small">
            <TableHead sx={{ whiteSpace: "nowrap", background: "#EEEDEB", textTransform: "capitalize" }}>
              <TableRow>
                {filteredNonColumns.map((column, columnIndex) => (
                  <TableCell
                    key={column.id}
                    sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: `${columnIndex == 0 ? "left" : "center"}` }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNonData?.map((rowData, rowIndex) => (
                <TableRow key={rowIndex}>
                  {filteredNonColumns.map((column) => {
                    if (column.id === "label") {
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                        >
                          {rowData.riskFactorDisease}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={column.id}
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                      >
                        {rowData[column.id]?.value ? "Y" : ""}&nbsp;&nbsp;
                        {rowData[column.id]?.value && (
                          <span
                            className="risk-level-circle"
                            style={{ background: getColorByLabel(rowData[column.id]?.riskLevel) }}
                          ></span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Col>

        <Col lg={12}>  
        <TableContainer
          component={Paper}
          className="risk-disease-map-table mb-2"
        >
          <Table size="small">
            {/* <TableBody>  
              {filteredNonData?.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {filteredNonColumns.map((column) => {
                      if (column.id === "label") {
                        return (
                          <TableCell
                            key={column.id}
                            sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                          >
                            {rowData.riskFactorDisease}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                        >
                          {rowData[column.id]?.value && (
                            <span
                            >{rowData[column.id]?.riskLevel}</span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody> */}
             <TableHead sx={{ whiteSpace: "nowrap",  textTransform: "capitalize" }}>
             {filteredNonData?.map((rowData, rowIndex) => (
                <TableRow key={rowData.id}>
                  {filteredColumns?.map((column) => (
                    <>{column.id === "cld" || column.id === "overWeightObesity" || column.id === "highBloodPressure" ? <TableCell
                      key={column.id}
                      sx={{minWidth: "240px", borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                    >
                      {column.id === 'label'
                        ? rowData.riskFactorDisease
                        : filteredNonData[0][column.id]?.value
                          ? filteredNonData[0][column.id]?.riskLevel
                          : null
                      }
                    </TableCell>  : <TableCell
                    key={column.id}
                    sx={{minWidth: column.id === 'label' ? "185px" :"110px", borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                  >
                    {column.id === 'label'
                      ? rowData.riskFactorDisease
                      : filteredNonData[0][column.id]?.value
                        ? filteredNonData[0][column.id]?.riskLevel
                        : null
                    }
                  </TableCell>}</>
                    
                  ))}
                </TableRow>

             ))}
            
            </TableHead>
          </Table>
        </TableContainer>
        </Col>
      
    </Row>

    {/* <Row>
      <Col lg={12}>
        <TableContainer
          component={Paper}
          className="risk-disease-map-table mb-2"
        >
          <Table size="small">
            <TableBody>  
              {filteredNonData?.map((rowData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {filteredNonColumns.map((column) => {
                      if (column.id === "label") {
                        return (
                          <TableCell
                            key={column.id}
                            sx={{ width: "300px", borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                          >
                            {rowData.riskFactorDisease}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)", textAlign: "center" }}
                        >
                          {rowData[column.id]?.value && (
                            <span
                            >{rowData[column.id]?.riskLevel}</span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row> */}

    <Row>
      <Col>
          <div className="meter-content-wrapper mb-5">
            <div className="meter-content-inner summery">
              <div className="meter-head">
                <h6>Summary </h6>
              </div>
              <div className="meter-content">
                <p>
                Your Lifestyle Analysis mapped with Risk Factors and  the same then mapped to Lifestyle Diseases shows that you are at risk for following Diseases.
                </p>
              </div>
              <div style={{marginTop: "10px"}}>
                  <ul style={{listStyle: "disc"}}>
                    {keys.map((key,index) => (
                      <li key={index}><h6>{key}</h6></li>
                    ))}
                  </ul> 
              </div>
              <div className="clickHereText">
                Please checkout the Preventive care section for recommendations for next steps.
              </div>
            </div>
          </div>
      </Col>
    </Row>

    </>
  );
};

export default RisksDiseaseMap;
